<template>
	<div class="app-container">
		<el-tabs v-model="activeTab" @tab-click="handleClick" v-loading="loading">
			<!-- Begin: Leaderboard Settings Information -->
			<el-tab-pane label="Group List" name="List">
				<el-table :data="allClanLDBSettings" border style="width: 100%">
					<el-table-column label="Index" type="index" width="60px" />

					<el-table-column label="Group Name">
						<template slot-scope="{ row }">
							<span>{{ row.ldbGroupName }}</span>
						</template>
					</el-table-column>

					<el-table-column label="Event ID" width="200px">
						<template slot-scope="{ row }">
							<template v-if="row.editMode">
								<el-input
									v-model="row.eventID"
									class="edit-input"
									size="small"
								/>
							</template>
							<span v-else>{{ row.eventID }}</span>
						</template>
					</el-table-column>

					<el-table-column label="Date" width="220px">
						<template slot-scope="{ row }">
							<template v-if="row.editMode">
								From:
								<el-date-picker
									v-model="row.startDateTime"
									type="datetime"
									placeholder="Select date and time"
									format="dd/MM/yyyy HH:mm:ss"
								>
								</el-date-picker>
								<br /><br />
								To:
								<el-date-picker
									v-model="row.endDateTime"
									type="datetime"
									placeholder="Select date and time"
									format="dd/MM/yyyy HH:mm:ss"
								>
								</el-date-picker>
							</template>
							<span v-else
								>From:
								{{
									row.startDateTime.toLocaleString("en-GB", {
										hour12: false,
									})
								}}
								<br /><br />
								To:
								{{
									row.endDateTime.toLocaleString("en-GB", {
										hour12: false,
									})
								}}
							</span>
							<br />
						</template>
					</el-table-column>

					<el-table-column
						label="Segment & Rewards"
						width="200px"
						type="expand"
					>
						<template slot-scope="{ row }">
							<el-collapse>
								<el-collapse-item title="Segment" name="1">
									<el-card class="box-card" style="min-height: 150px">
										<el-table v-bind:data="row.segmentConfig" border="">
											<el-table-column label="Group Name" width="300px">
												<template slot-scope="{ row }">
													<span>{{ row.name }}</span>
												</template>
											</el-table-column>

											<el-table-column label="Total LDB" width="200px">
												<template slot-scope="{ row }">
													<template v-if="propEditMode">
														<el-input
															placeholder="Please input value"
															v-model="row.total"
															@change="handleChange"
														/>
													</template>
													<span v-else>
														{{ row.total }}
													</span>
												</template>
											</el-table-column>

											<el-table-column label="Total Clan" width="100px">
												<template slot-scope="{ row }">
													<template v-if="propEditMode">
														<el-input
															placeholder="Please input value"
															v-model="row.size"
															@change="handleChange"
														/>
													</template>
													<span v-else>
														{{ row.size }}
													</span>
												</template>
											</el-table-column>
										</el-table>
									</el-card>
								</el-collapse-item>
								<el-collapse-item title="Rewards" name="2">
									<el-card class="box-card" style="min-height: 150px">
										<el-table v-bind:data="row.rewardsConfig" border>
											<el-table-column label="ID" width="50px">
												<template slot-scope="{ row }">
													<span>{{ row.id }}</span>
												</template>
											</el-table-column>

											<el-table-column label="Title" width="200px">
												<template slot-scope="{ row }">
													<template v-if="propEditMode">
														<el-input
															placeholder="Please input value"
															v-model="row.title"
															@change="handleChange"
														/>
													</template>
													<span v-else>
														{{ row.title }}
													</span>
												</template>
											</el-table-column>

											<el-table-column label="Rank Min" width="100px">
												<template slot-scope="{ row }">
													<template v-if="propEditMode">
														<el-input
															placeholder="Please input value"
															v-model="row.rankMin"
															@change="handleChange"
														/>
													</template>
													<span v-else>
														{{ row.rankMin }}
													</span>
												</template>
											</el-table-column>

											<el-table-column label="Rank Max" width="100px">
												<template slot-scope="{ row }">
													<template v-if="propEditMode">
														<el-input
															placeholder="Please input value"
															v-model="row.rankMax"
															@change="handleChange"
														/>
													</template>
													<span v-else>
														{{ row.rankMax }}
													</span>
												</template>
											</el-table-column>

											<el-table-column label="Required Score" width="125px">
												<template slot-scope="{ row }">
													<template v-if="propEditMode">
														<el-input
															placeholder="Please input value"
															v-model="row.requiredScore"
															@change="handleChange"
														/>
													</template>
													<span v-else>
														{{ row.requiredScore }}
													</span>
												</template>
											</el-table-column>

											<el-table-column label="Rewards">
												<template slot-scope="{ row }">
													<template v-if="propEditMode">
														<el-input
															placeholder="Please input value"
															v-model="row.rewards"
															@change="handleChange"
														/>
													</template>
													<span v-else>
														{{ row.rewards }}
													</span>
												</template>
											</el-table-column>
										</el-table>
									</el-card>
								</el-collapse-item>
							</el-collapse>
						</template>
					</el-table-column>

					<el-table-column label="Owner">
						<template slot-scope="{ row }">
							<span>{{ row.createdBy }}</span>
						</template>
					</el-table-column>

					<el-table-column label="Action">
						<template slot-scope="{ row }">
							<el-button
								type="danger"
								size="small"
								icon="el-icon-delete"
								@click="onDeleteOneClanLDBSetting(row)"
							>
								Delete</el-button
							>

							<el-button
								type="danger"
								size="small"
								icon="el-icon-refresh"
								@click="onResetOneClanLDBSetting(row)"
							>
								Reset</el-button
							>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<!-- End: Leaderboard Information -->

			<!-- Begin: Add new Leaderboard -->
			<el-tab-pane label="Add New" name="AddNew">
				<el-collapse v-model="activeCollapseName">
					<el-collapse-item name="1">
						<template slot="title">
							<h2>Event Information</h2>
						</template>
						<el-card class="box-card" style="min-height: 150px">
							<el-form label-width="200px">
								<el-form-item label="Event Type List">
									<el-select
										v-model="currentEventType"
										filterable
										allow-create
										placeholder="Select"
										@change="onSelectEventTypeFromList()"
										style="width: 16%"
									>
										<el-option
											v-for="item in eventTypeList"
											:key="item"
											:label="item"
											:value="item"
										>
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item label="Event ID List">
									<el-select
										v-model="currentEventID"
										filterable
										allow-create
										placeholder="Select"
										@change="onSelectEventIDFromList($event)"
										style="width: 16%"
									>
										<el-option
											v-for="item in eventInformationList"
											:key="item.eventID"
											:label="item.eventID"
											:value="item.eventID"
										>
										</el-option>
									</el-select>
								</el-form-item>
								<br />

								<el-form-item label="Event ID" style="width: 40%">
									<el-input
										v-model="currentEventInformation.eventID"
										class="edit-input"
										placeholder="Auto fill when selecting Event ID !"
									/>
								</el-form-item>
								<br />

								<el-form-item label="Event Name" style="width: 40%">
									<el-input
										v-model="currentEventInformation.eventName"
										class="edit-input"
										placeholder="Auto fill when selecting Event ID !"
									/>
								</el-form-item>
								<br />
								<el-form-item label="Start Date Time">
									<el-date-picker
										v-model="currentEventInformation.startDateTime"
										type="datetime"
										placeholder="Auto fill !"
									>
									</el-date-picker>
								</el-form-item>
								<br />
								<el-form-item label="End Date Time">
									<el-date-picker
										v-model="currentEventInformation.endDateTime"
										type="datetime"
										placeholder="Auto fill !"
									>
									</el-date-picker>
								</el-form-item>
								<br />
							</el-form>
						</el-card>
					</el-collapse-item>

					<el-collapse-item name="2">
						<template slot="title">
							<h2>Leaderboard Segment Groups</h2>
						</template>
						<el-card class="box-card" style="min-height: 150px">
							<el-form label-width="200px">
								<el-form-item label="Country Segment">
									<el-select
										v-model="addLDBGroup.segments.country"
										placeholder="Select"
										style="width: 20%"
									>
										<el-option
											v-for="item in countrySegmentList"
											:key="item"
											:label="item"
											:value="item"
										>
										</el-option>
									</el-select>

									<template v-if="addLDBGroup.segments.country.includes('TIER1')">
										<span>
											<br />
											(all users in the tier1 countries: GB, FR, DE, IT, AU, CA,
											KR, JP, TW, HK)
										</span>
									</template>

									<template v-if="addLDBGroup.segments.country.includes('TIER3')">
										<span>
											<br />
											(all users in the TIER3 countries: IN, PH, ID)
										</span>
									</template>
								</el-form-item>
								<br />

								<el-form-item label="Total LDB" style="width: 20%">
									<el-input v-model="addLDBGroup.total" class="edit-input" />
								</el-form-item>
								<br />

								<el-form-item label="Total Clan" style="width: 20%">
									<el-input v-model="addLDBGroup.size" class="edit-input" />
								</el-form-item>
								<br />
							</el-form>

							<el-button
								type="success"
								size="small"
								icon="el-icon-edit-outline"
								@click="onAddLDBGroup()"
							>
								Add Group
							</el-button>

							<el-button
								type="success"
								size="small"
								icon="el-icon-edit-outline"
								@click="onAddLDBGroupQuick()"
							>
								Quick Add Group Default
							</el-button>

							<br />
							<br />

							<el-table :data="addLDBGroupList" border style="width: 100%">
								<el-table-column prop="name" label="Group Name" />
								<el-table-column
									prop="total"
									label="Total LDB"
									width="100"
								/>
								<el-table-column prop="size" label="Total Clan" width="100" />

								<el-table-column label="Action">
									<template slot-scope="{ row }">
										<el-button
											type="danger"
											size="small"
											icon="el-icon-delete"
											@click="onDeleteOneLDBGroup(row)"
										>
											Delete
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-card>
					</el-collapse-item>

					<el-collapse-item name="3">
						<template slot="title">
							<h2>Leaderboard Rewards</h2>
						</template>
						<el-card class="box-card" style="min-height: 150px">
							<el-form label-width="200px">
								<el-form-item label="Import Rewards Files:" style="width: 50%">
									<el-upload
										class="upload-demo"
										drag
										action=""
										ref="upload2"
										:auto-upload="false"
										:multiple="true"
										:on-change="onHandleRewardFileUpload"
										:on-remove="onHandleRewardFileRemove"
									>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text">Drop *.mlb files here!</div>
									</el-upload>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="onProcessRewardFiles">
										Loading Rewards
									</el-button>
								</el-form-item>
							</el-form>

							<br />
							<br />

							<div class="info">
								<el-table :data="addLDBRewardList" border style="width: 100%">
									<el-table-column prop="id" label="ID" width="50" />
									<el-table-column prop="title" label="Title" width="180" />
									<el-table-column prop="rankMin" label="Rank Min" width="100">
									</el-table-column>

									<el-table-column prop="rankMax" label="Rank Max" width="100">
									</el-table-column>

									<el-table-column
										prop="requiredScore"
										label="Required Score"
										width="130"
									>
									</el-table-column>

									<el-table-column prop="rewards" label="Rewards" />
								</el-table>
							</div>
						</el-card>
					</el-collapse-item>
				</el-collapse>

				<br />
				<br />

				<el-button
					type="success"
					size="small"
					icon="el-icon-edit-outline"
					@click="onAddNewLeadboardGroup()"
					>Add Leader Board</el-button
				>
			</el-tab-pane>
			<!-- End: Add new Leaderboard -->
		</el-tabs>
	</div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
import { getEventDataFromFirebase, getEventDataFromFirebaseV2 } from "@/api/dynamic_events";

import store from "@/store";
import apiGeneral from "@/api/apiGeneral.js";
import apiClanLDB from "@/api/apiClanLDB.js";
import { SERVER_RESPONSE_CODE } from "@/defines/constants";

var fs = require("fs");

export default {
	name: "ClanLDB",
	components: {},
	data() {
		return {
			loading: false,
			activeCollapseName: ["1", "2", "3"],
			activeTab: "List",

			// add new ClanLDB Settings
			eventTypeList: ["EVENT_LBQ", "EVENT_ENDLESS", "EVENT_BOSS_RAID"],
			osList: ["ANDROID", "IOS"],
			countrySegmentList: [
				"US",
				"TIER1",
				"TIER3",
				"OTHERS",
				"CHEATERS",
				"TESTERS",
			],

			addLDBGroup: {
				name: "",
				segments: {
					country: "US"
				},
				total: 1,
				size: 100,
			},
			addLDBGroupList: [],
			addLDBRewardList: [],

			eventInformationList: [],
			currentEventType: "",
			currentEventID: "",
			currentEventInformation: {},



			// All ClanLDB Setting
			propEditMode: false,
			allClanLDBSettings: [],

			clanLDBStatusList: [],
		};
	},
	async created() {
		this.loading = false;
		this.currentEventID = "";
		this.currentEventInformation = {};

		this.addLDBGroupList = [];
		this.addLDBRewardList = [];
		this.eventInformationList = [];
		this.allClanLDBSettings = [];

		this.propEditMode = false;

		this.clanLDBStatusList = [];

		await this.getAndLoadAllClanLDBSetting();
	},
	methods: {
		// ========================================
		isStringAlphaNumber(input) {
			var letters = /^[0-9a-zA-Z_]+$/;
			if (input.match(letters)) {
				return true;
			} else {
				return false;
			}
		},

		// ========================================
		async handleClick(tab, event) {
			if (tab.name === "Information") {
				await this.getAndLoadAllClanLDBSetting();
			}
		},

		// ========================================
		async getAndLoadAllClanLDBSetting() {
			try {
				this.loading = true;
				let returnData = await apiClanLDB.getAllClanLDBSettings();

				this.loading = false;

				if (returnData !== null &&
					returnData !== undefined &&
					returnData.code !== null &&
					returnData.code !== undefined &&
					returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {

					this.allClanLDBSettings = returnData.data;
					this.allClanLDBSettings.forEach((clanSetting) => {
						clanSetting.startDateTime = new Date(clanSetting.startTimestamp * 1000);
						clanSetting.endDateTime = new Date(clanSetting.endTimestamp * 1000);
					});
				}
			}
			catch (error) {
				this.loading = false;
				console.log(error);
			}
		},

		// ========================================
		// load thong tin tat ca Events tu Firebase
		loadingEventInformation(eventType) {
			this.eventInformationList = [];

			getEventDataFromFirebase()
				.then((response) => {
					response.events.forEach((eventInfo) => {
						if (eventInfo.event_type === eventType) {
							let displayDateBegin = new Date(
								eventInfo.date_begin._seconds * 1000
							);
							let displayDateEnd = new Date(
								eventInfo.date_end._seconds * 1000
							);

							this.eventInformationList.unshift({
								eventID: eventInfo.event_id,
								eventType: eventInfo.event_type,
								eventName: eventInfo.event_name,
								startDateTime: displayDateBegin,
								endDateTime: displayDateEnd,
								startTimestamp: eventInfo.date_begin._seconds,
								endTimestamp: eventInfo.date_end._seconds,
							});
						}
					});
				})
				.catch((error) => {
					Message({
						message: "Can't fetch config data list " + error,
						type: "error",
						duration: 5 * 1000,
					});
				});
		},

		// ========================================
		onAddLDBGroup() {
			let isExist = false;
			this.addLDBGroup.name = this.addLDBGroup.segments.country.toLowerCase();
			this.addLDBGroup.segments.country = this.addLDBGroup.segments.country.toLowerCase();

			for (let idx = 0; idx < this.addLDBGroupList.length; idx++) {
				if (this.addLDBGroupList[idx].name === this.addLDBGroup.name) {
					isExist = true;
					break;
				}
			}

			if (isExist) {
				Message({
					message: "LeaderBoard Group Name is already existing!",
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			let tempGroup = {};
			tempGroup = JSON.parse(JSON.stringify(this.addLDBGroup));

			this.addLDBGroupList.push(tempGroup);
		},

		// ========================================
		onAddLDBGroupQuick() {
			this.countrySegmentList.forEach((countrySegment) => {
				let isExist = false;
				this.addLDBGroup.name = countrySegment.toLowerCase();
				this.addLDBGroup.segments.country = countrySegment.toLowerCase();

				for (let idx = 0; idx < this.addLDBGroupList.length; idx++) {
					if (this.addLDBGroupList[idx].name === this.addLDBGroup.name) {
						isExist = true;
						break;
					}
				}

				if (!isExist) {
					let tempGroup = {};
					tempGroup = JSON.parse(JSON.stringify(this.addLDBGroup));
					this.addLDBGroupList.push(tempGroup);
				}
			});
		},

		// ========================================
		onSelectEventTypeFromList() {
			this.loadingEventInformation(this.currentEventType);
		},

		// ========================================
		onSelectEventIDFromList(eventID) {
			this.eventInformationList.forEach((eventInfo) => {
				if (eventInfo.eventID === eventID) {
					this.currentEventInformation = eventInfo;
					return;
				}
			});
		},

		// ========================================
		onDeleteOneLDBGroup(row) {
			let rowIdx = -1;
			for (let idx = 0; idx < this.addLDBGroupList.length; idx++) {
				if (this.addLDBGroupList[idx].name === row.name) {
					rowIdx = idx;
					break;
				}
			}

			if (rowIdx >= 0) {
				this.addLDBGroupList.splice(rowIdx, 1);
			}
		},

		// ========================================
		onHandleRewardFileRemove(oneFile, fileList) { },

		// ========================================
		onHandleRewardFileUpload(oneFile, fileList) {
			this.ldbRewardsConfigFileList = fileList;
		},

		// ========================================
		async onProcessRewardFiles() {
			if (this.ldbRewardsConfigFileList.length <= 0) {
				return;
			}

			this.addLDBRewardList = [];
			let rewardConfigData = await apiGeneral.loadingTextOneFile(this.ldbRewardsConfigFileList[0]);
			rewardConfigData = rewardConfigData.replaceAll(`""`, `"`);
			let convertedData = apiGeneral.convertMLBToJson(rewardConfigData);

			convertedData.forEach((oneRowData) => {
				this.addLDBRewardList.push({
					id: Number(oneRowData.index),
					title: oneRowData.Title,
					rankMin: oneRowData.RankMin,
					rankMax: oneRowData.RankMax,
					requiredScore: oneRowData.RequiredScore,
					rewards: oneRowData.Rewards,
				});
			});
		},

		// ========================================
		// Add new LDB Group
		async onAddNewLeadboardGroup() {
			if (this.currentEventInformation.eventID === undefined) {
				Message({
					message: `Select an Event ID!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			if (this.currentEventInformation.eventName === undefined ||
				this.currentEventInformation.eventName.length <= 0) {
				Message({
					message: `Event Name is empty!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			if (this.currentEventInformation.startDateTime === undefined) {
				Message({
					message: `Start Datetime is not exist!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			if (this.currentEventInformation.endDateTime === undefined) {
				Message({
					message: `End Datetime is not exist!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			if (this.addLDBGroupList.length <= 0) {
				Message({
					message: `Please Input ClanLDB Segment Group!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			if (this.addLDBRewardList.length <= 0) {
				Message({
					message: `Please Input ClanLDB Rewards!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			try {
				this.loading = true;
				let returnData = await apiClanLDB.addClanLDBSettings(
					this.currentEventInformation.eventID,
					this.currentEventInformation.eventType,
					this.currentEventInformation.startTimestamp,
					this.currentEventInformation.endTimestamp,
					this.addLDBGroupList,
					this.addLDBRewardList,
					store.getters.email
				);

				this.loading = false;

				if (returnData !== null &&
					returnData !== undefined &&
					returnData.code !== null &&
					returnData.code !== undefined &&
					returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {

					this.addLDBGroupList = [];
					this.addLDBRewardList = [];
					this.currentEventID = "";
					this.currentEventInformation = {};

					Message({
						message: "Add new LDB Group successful!",
						type: "success",
						duration: 5 * 1000,
					});

					await this.getAndLoadAllClanLDBSetting();
				} else {
					Message({
						message: returnData.message,
						type: "error",
						duration: 5 * 1000,
					});
				}
			}
			catch (error) {
				this.loading = false;
				console.log(error);
				Message({
					message: error.message,
					type: "error",
					duration: 5 * 1000,
				});
			}
		},

		// ========================================
		async onDeleteOneClanLDBSetting(row) {
			let tempConfirm = undefined;
			try {
				tempConfirm = await MessageBox.confirm(
					`Do you confirm to delete ClanLDB Group: ${row.ldbGroupName}?` + "\n" +
					`It will be CLEAR all ClanLDB Event Data!`,
					"Confirm Delete",
					{
						confirmButtonText: "Delete",
						cancelButtonText: "Cancel",
						type: "warning",
					}
				);
			} catch (error) {
				console.log(error);
				return;
			}

			if (tempConfirm != "confirm") {
				return;
			}

			try {
				this.loading = true;
				let returnData = await apiClanLDB.deleteClanLDBSettings(row.ldbGroupName);

				if (returnData !== null &&
					returnData !== undefined &&
					returnData.code !== null &&
					returnData.code !== undefined &&
					returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {

					Message({
						message: "Delete ClanLDB Group successful!",
						type: "success",
						duration: 5 * 1000,
					});

					await this.getAndLoadAllClanLDBSetting();

					this.loading = false;

				} else {
					this.loading = false;

					Message({
						message: returnData.message,
						type: "error",
						duration: 5 * 1000,
					});
				}
				
			} catch (error) {
				this.loading = false;
				console.log(error);
			}
		},

		// ========================================
		async onResetOneClanLDBSetting(row) {
			let tempConfirm = undefined;
			try {
				tempConfirm = await MessageBox.confirm(
					`Do you confirm to reset ClanLDB Group: ${row.ldbGroupName}?` + "\n" +
					`It will be CLEAR all ClanLDB Event Data!`,
					"Confirm Reset",
					{
						confirmButtonText: "Reset",
						cancelButtonText: "Cancel",
						type: "warning",
					}
				);
			} catch (error) {
				console.log(error);
				return;
			}

			if (tempConfirm != "confirm") {
				return;
			}

			try {
				this.loading = true;

				let startTimestamp = null;
				let endTimestamp = null;

				let eventData = await getEventDataFromFirebaseV2();

				
				if (eventData == null || eventData === undefined) {
					this.loading = false;
					Message({
						message: "Can't fetch config data list!",
						type: "error",
						duration: 5 * 1000,
					});

					return;
				}

				let eventDataList = eventData.events;

				console.log(eventDataList);

				for(let i=0; i< eventDataList.length; i++) {
					let eventInfo = eventDataList[i];
					if (eventInfo.event_id === row.eventID) {							
						startTimestamp = eventInfo.date_begin._seconds;
						endTimestamp = eventInfo.date_end._seconds;
						break;
					}
				}

				console.log(row.ldbGroupName);
				console.log(startTimestamp);
				console.log(endTimestamp);

				let returnData = await apiClanLDB.resetClanLDBSettings(row.ldbGroupName, startTimestamp, endTimestamp);

				if (returnData !== null &&
					returnData !== undefined &&
					returnData.code !== null &&
					returnData.code !== undefined &&
					returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {

					Message({
						message: "Reset ClanLDB Group successful!",
						type: "success",
						duration: 5 * 1000,
					});

					await this.getAndLoadAllClanLDBSetting();

					this.loading = false;

				} else {
					this.loading = false;

					Message({
						message: returnData.message,
						type: "error",
						duration: 5 * 1000,
					});
				}
				
			} catch (error) {
				this.loading = false;
				console.log(error);
			}
		},
	},
};
</script>
